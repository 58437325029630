<template>
  <div class="stepper">
    <div class="spinner">
      <v-container fill-height fluid v-if="spinnerState">
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate :size="150" color="primary" class=""></v-progress-circular>
        </v-row>
      </v-container>
    </div>
    <v-app-bar flat class="stepper-header" v-if="!spinnerState">
      <div class="appbar-left-side">
          <v-icon
            :ripple="false"
            @click="previousStepHandler()"
            large
          >mdi-chevron-left
        </v-icon>
        <span class="add-link-text">Add link</span>
        <span class="step-text">Step {{currentStep}}</span>
      </div>
      <div class="appbar-right-side">
        <v-btn
          depressed
          class="white--text"
          color="#063BFF"
          v-if="!step1"
          @click="saveConfig()"
        >SAVE
        </v-btn>
      </div>
    </v-app-bar>
    <div class="step1" v-if="step1">
      <v-form
        v-if="!spinnerState"
        v-model="valid"
        @submit.prevent="nextStepHandler"
        @keydown.prevent.enter>
        <div class="source-inputs-container">
          <div class="inputs">
            <v-text-field
              v-model="sourceObj.title"
              label="Title"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="sourceObj.provider"
              label="Provider"
              outlined
              required
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
             v-model="sourceObj.source"
             label="Source"
             outlined
             required
             :rules="linkRules"
            ></v-text-field>
            <v-text-field
              v-model="sourceObj.frequency"
              label="Frequency"
              hint="For example: * * * * 2 , * 1 * * * etc."
              outlined
              required
              :rules="cronRules"
            ></v-text-field>
          </div>
        </div>
      </v-form> 
      <div class="error2" v-if="steperOneError && !spinnerState" >
        <p>{{steperOneError}}</p>
      </div>
        <v-btn
          :disabled="!valid"
          depressed
          class="next-btn white--text"
          color="#063BFF"
          @click="nextStepHandler()"
          v-if="!spinnerState"
        >NEXT
        </v-btn>
    </div>
    <div class="step2" v-if="!step1 && !spinnerState">
        <div class="step2-table">
          <v-data-table
            :headers="headers"
            :items="sourceKeys"
            hide-default-footer
            disable-pagination
            item-key="Name"
            class="elevation-1"
          >
            <template v-slot:[`item.path`]="{ item }">
            <div class="select-holder">
              <v-autocomplete
                :items="mapOptions"
                :value="getModel(item.key)"
                v-model="sourceObj.config_object[item.key]"
                dense
                outlined
                label="Select target"
              ></v-autocomplete>
            </div>
            </template>    
          </v-data-table>
        </div>   
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
  export default {
    name: 'Stepper',
    data: () => ({
      steperOneError: '',
      spinnerState: false,
      editMode:false,
      willMatch : true,
      valid :false,
      currentStep:1,
      step1: true,
      loading: false,
      sourceKeys:[],
      example:{},
      sourceObj:{
        alert: 0,
        successfullRuns: 0,
        failedRuns: 0,
        provider: '',
        title: '',
        source:'',
        frequency:'',
        lastRun: new Date("2000-1-2"),
        lastRunStatus: 'NOT_RUN_YET',
        config_object:{}
      },
      mapOptions: [],
      headers: [
        { text: 'KEY', value: 'key' },
        { text: 'TARGET', value: 'path', width: '200' },
      ],
      rules: {
          required: value => !!value || 'Required',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
      linkRules: [
        v => /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
        .test(v) || 'Enter valid url',
      ],
      cronRules: [
        v => /(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/
        .test(v) || 'Enter a valid cron format',
        (v) =>
          (v && v.length <= 20) ||
          "Cron format must be less than 20 characters",
    ], 
    }),
    mounted(){
      if(this.$route.query.id){
        this.editMode = true;
        this.getSource(this.$route.query.id)
      }
      this.getTargetOptions();
    },
    methods: {
      ...mapActions('source', { findSourceDetails: 'find' }),
      ...mapActions('target', { findTargetOptions: 'find' }),
      ...mapActions('preview', { findPreviews: 'find' }),

      nextStepHandler(){
        this.spinnerState = true;
        this.getSourceConfiguration(this.sourceObj.source);
      },

      previousStepHandler(){
        if(!this.step1){
          this.step1 = true;
          this.currentStep = 1;
          return;
        }
        this.$router.go(-1)
      },

      getTargetOptions(){
        this.findTargetOptions()
          .then((targets) => {
            console.log(targets);
            this.mapOptions = targets.data[0].mapOptions;
            this.mapOptions.unshift('');
          })
          .catch(err => {
            console.error(err)
          });
      },

      getSourceConfiguration(source){
        if(this.editMode){
          this.sourceKeys = Object.keys(this.sourceObj.config_object).map((objKey) => ({key: objKey, path:''}));
          this.steperOneError = "";
          this.spinnerState = false;
          this.step1 = false;
          this.currentStep = 2;
          return

        }

        this.findPreviews({ query: { link: source }})
          .then((response) => {
            this.example = {
              ...response
            };
            this.steperOneError = "";
            this.spinnerState = false;
            this.step1 = false;
            this.currentStep = 2;
            this.transformKeys();
          }).catch(err => {
            console.error(err)
            this.spinnerState = false;
            if(err.code !== 408) {
              this.steperOneError = "Error: No configuration for this link";
            }else {
              this.steperOneError = "Timeout of 5000ms exceeded calling find on preview"
            }
          });
      },

      saveConfig(){
        this.spinnerState = true;
        this.willMatch = false;
        this.sourceObj.config_object = JSON.stringify(this.sourceObj.config_object);
        this.editMode ? this.editSource() : this.addSource();
      },

      getSource(id){
        this.findSourceDetails({ query: { _id: id } })
        .then(response => {
          this.sourceObj = {
            ...response.data[0],
            config_object: JSON.parse(response.data[0].config_object)
          }
        }).catch(err => {
          console.error(err);
        })
      },

      transformKeys(){
        this.sourceKeys = this.example.data.map((key) =>({key:key, path:''}));
        if(!this.editMode){
          this.example.data.forEach(element => {
            this.sourceObj.config_object[element] = '';
          });
        }
      },

      getModel(itemKey){
        if(this.sourceObj.config_object[itemKey]){
          return this.sourceObj.config_object[itemKey]
        }
        if(!this.editMode && this.willMatch){
          let keyArr = itemKey.split('.');
          if(this.mapOptions.includes(keyArr[keyArr.length - 1])){
            this.sourceObj.config_object[itemKey] = keyArr[keyArr.length - 1];
            return keyArr[keyArr.length - 1];
          }
        }
      },

      editSource(){
        const { Source } = this.$FeathersVuex;
        const newSource = new Source(this.sourceObj);
        newSource.patch(newSource)
        .then(()=> {
          this.$emit('sourceAdded', 'NOTIFICATION: Source has been edited!')
          this.spinnerState = false;
          this.$router.push('dashboard');
        })
        .catch((err) => {
          console.error(err)
          this.spinnerState = false;
        });
      },

      addSource(){
        const { Source } = this.$FeathersVuex;
        const newSource = new Source(this.sourceObj);
        newSource.save()
          .then(()=> {
            this.$emit('sourceAdded', 'NOTIFICATION: Source has been added!')
            this.$router.push('dashboard');
            this.spinnerState = false;
          }).catch(err => {
            this.spinnerState = false;
            console.error(err)
          });
      }
    }
  }
</script>

<style scoped>
  .v-select.v-text-field:not(.v-text-field--single-line) input {
      margin-top: 24px;
  }

  .error2{
    margin-bottom: 0px;
    margin-top: 15px;
    color: red;
  }

  .spinner {
    display: block;
    position: fixed;
    z-index: 1031; 
    top: 50%;
    right: 50%; 
    margin-top: -75px; 
    margin-right: -75px;
  }

  .v-icon:after {
    opacity: 0 !important;
  }

  .v-toolbar__content{
    display: flex;
    justify-content: space-between;
  }

  .appbar-left-side{
    display: flex;
    align-items: center;
  }
  .step1{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .add-link-text{
    font-size: 20px;
    margin-right: 10px;
  }

  .source-inputs-container{
    width: 560px;
    background-color:white;
    padding:40px;
    border-radius: 6px;
    border:0.5px solid rgb(231, 230, 230);
  }

  .inputs{
    margin:auto;
    width: 400px;
  }

  .next-btn{
    width: 360px;
    color:white;
    margin:32px;
  }

  .step-text{
    font-size: 20px;
    color:rgb(95, 93, 93);
  }

  .step2{
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .step2-table{
    width:1200px;
    padding:40px;
    margin: auto;
  }
  
  .select-holder{
    padding-top: 15px;
  }

</style>
