<template>
  <div class="dashboard-container">
    <ConfirmDialog 
      :headerText="'Are you sure you want to delete this secret key?'" 
      :dialog="dialog"
      @selectedYes="deleteSecretKey()"
      @selectedNo="dialog = false"
      @dialogClosed="dialog = false"
    />

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          snackbarText
          v-bind="attrs"
          @click="snackbar = false"
        >Close
        </v-btn>
      </template>
    </v-snackbar>


    <v-card>
      <v-card-title>
        Secret keys
        <v-spacer></v-spacer>
      </v-card-title>

      <v-data-table
        @pagination="updatePagination"
        :headers="headers"
        :items="rows"
        :footer-props="{
            'items-per-page-options': [5, 10, 15]
          }"
        :items-per-page="5"
      >
       <template v-slot:[`item.key`]="{ item }">
         <div class="key-column">
           <p style="margin-bottom:0px">{{item.key}}</p>
            <v-icon class="link-btn" v-clipboard:copy="item.key" @success="copySuccessfull" >
              content_copy
            </v-icon>
         </div> 
       </template>

        <template v-slot:[`item.secret`]="{ item }">
         <div class="key-column">
           <p style="margin-bottom:0px">{{item.secret}}</p>
            <v-icon class="link-btn" v-clipboard:copy="item.secret" @success="copySuccessfull" >
              content_copy
            </v-icon>
         </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="selectKey(item)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
import ConfirmDialog from '../shared/ConfirmationDialog.vue';
import * as external from '../../feathers-client';

  export default {
    mounted() {
      this.findkeys({ query: { $limit: this.limit}})
        .then(response => {
          this.totalKeys = response.total;
          this.rows = response.data;
        }).catch(err => {
          console.error(err);
        });

      external.default.io.on("elasticsecrets removed", (elasticSecret) => {
        this.elasticSecretDeleted(elasticSecret._id);
        this.totalKeys--;
      });

      external.default.io.on("elasticsecrets created", (elasticSecret) => {
        this.rows.push(elasticSecret);
        this.totalKeys++;
      });
      
    },
    data () {
      return {
        limit:5,
        totalKeys:0,
        snackbar:false,
        snackbarText:'',
        key:{},
        dialog : false,
        selectedKey:'',
        headers: [
          { text: 'Key', value: 'key' },
          { text: 'Secret', value: 'secret' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        rows: [],
      }
    },
    methods: {
      ...mapActions('elasticsecrets', { findkeys: 'find' }),
      updatePagination (pagination) {
        pagination.itemsLength = this.totalKeys;
        if(pagination.page - pagination.pageCount == 0  && this.totalKeys > this.rows.length){
          this.limit = pagination.itemsPerPage;
          this.loadMoreKeys(pagination.pageStop);
        }
      },

      loadMoreKeys(skip){
        this.findkeys({ query: { $limit: this.limit , $skip:skip}})
        .then(response => {
          this.totalKeys = response.total;
          this.rows = this.rows.concat(response.data)
        }).catch(err => {
          console.error(err);
        })
      },

      selectKey(key){
        this.selectedKey = key;
        this.dialog = true;
      },

      deleteSecretKey(){
        this.dialog = false;
        const { Elasticsecret } = this.$FeathersVuex;
        const mySecret = new Elasticsecret(this.selectedKey);
        mySecret.remove(this.selectedKey._id)
         .then(()=> {
          this.snackbarText = 'Successfully deleted a secret key';
          this.snackbar = true;
         })
         .catch((err) => {
           console.error(err)
         });
      },

      elasticSecretDeleted(id){
        this.rows = this.rows.filter((row) => row._id != id)
      },

      copySuccessfull(){
        this.snackbarText = 'Copy successfull!';
        this.snackbar = true;
      }
    },
    computed:{
      ...mapGetters('elasticsecrets', { findkeysInStore:'find'}),
      keys(){
        return this.findkeysInStore({query:{}}).data
      }
    },
    components:{
      ConfirmDialog
    }
  }
</script>


<style scoped>


.v-icon:after {
  opacity: 0 !important;
}

.key-column{
  display: flex;
}

.link-btn{
  cursor: pointer;
  padding-left: 10px !important;
  font-size: 20px;
}
</style>