var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('ConfirmDialog',{attrs:{"headerText":'Are you sure you want to delete this secret key?',"dialog":_vm.dialog},on:{"selectedYes":function($event){return _vm.deleteSecretKey()},"selectedNo":function($event){_vm.dialog = false},"dialogClosed":function($event){_vm.dialog = false}}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","snackbarText":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-card',[_c('v-card-title',[_vm._v(" Secret keys "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"footer-props":{
          'items-per-page-options': [5, 10, 15]
        },"items-per-page":5},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"key-column"},[_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v(_vm._s(item.key))]),_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.key),expression:"item.key",arg:"copy"}],staticClass:"link-btn",on:{"success":_vm.copySuccessfull}},[_vm._v(" content_copy ")])],1)]}},{key:"item.secret",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"key-column"},[_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v(_vm._s(item.secret))]),_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.secret),expression:"item.secret",arg:"copy"}],staticClass:"link-btn",on:{"success":_vm.copySuccessfull}},[_vm._v(" content_copy ")])],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.selectKey(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }