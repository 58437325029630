<template>
    <md-drawer 
      class="md-right"  
      :md-active.sync="showDetails"
    >
    <ConfirmDialog 
      :headerText="'Are you sure you want to run this source?'" 
      :dialog="dialog"
      @selectedYes="runSource()"
      @selectedNo="dialog = false"
      @dialogClosed="dialog = false"
    />

    <ConfirmDialog 
      :headerText="'Are you sure you want to delete this source?'" 
      :dialog="dialogDelete"
      @selectedYes="removeSourceHandler()"
      @selectedNo="dialogDelete = false"
      @dialogClosed="dialogDelete = false"
    />

     <ConfirmDialog 
      :headerText="'Are you sure you want to cancel this source run?'" 
      :dialog="dialogCancel"
      @selectedYes="cancelSourceRun()"
      @selectedNo="dialogCancel = false"
      @dialogClosed="dialogCancel = false"
    />

      <md-toolbar 
        class="details-header" 
        md-elevation="0"
      >
        <span class="md-title">
          {{sourceInfo.title}}
        </span>
        <div class="buttons">
          <md-button 
            v-if="getUserRole()"
            @click="dialogDelete = true" 
            class="md-icon-button">
              <md-icon>delete</md-icon>
          </md-button>
          <md-button 
            v-if="getUserRole()"
            class="md-icon-button" 
            @click="editSourceHandler()">
              <md-icon>mode_edit</md-icon>
          </md-button>
          <md-button 
            class="md-icon-button link" 
            v-clipboard:copy="sourceLinkCopied" 
            @success="handleSuccess">
              <md-icon>link</md-icon>
          </md-button>
           <v-btn
            color="error"
            @click="resetAlert()"
            v-if="this.sourceInfo.alert"
          >
            <v-icon left>
              notifications_off
            </v-icon>
            acknowledge
          </v-btn>
          <v-btn 
            v-if="getUserRole()"
            class="srcBtn" 
            @click="checkRuns()">
              RUN
          </v-btn>
        </div>
      </md-toolbar>
      <div class="main-drawer-content"> 
        <v-card 
          class="chart spacer" 
          elevation="1">
           <Chart :successRate="this.sourceInfo"/>
        </v-card>
        <v-card class="info-card spacer" elevation="1">
          <span class="info-card-text">LAST RUN</span>
          <span
            :class="sourceInfo.lastRunStatus == 'SUCCESS' ? 'last-run-info-success' : 'last-run-info-fail'">
            {{sourceInfo.lastRunStatus}}
          </span>
        </v-card>
        <v-card class="info-card spacer" elevation="1">
          <span class="info-card-text">FREQUENCY</span>
          <span class="frequency-info">{{sourceInfo.frequency}}</span>
        </v-card>

        <v-data-table
          @pagination="updateRunsTable"
          :headers="headers"
          :items="resoults"
          :loading = "runsLoading"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 15],
          }" 
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon 
              v-if="item.status == 'PROCESSING'" 
              @click="stopRunHandler(item)">
              highlight_off
            </v-icon>
          </template>
        </v-data-table>

        <v-snackbar
          v-model="snackbar"
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              snackbarTextt
              v-bind="attrs"
              @click="snackbar = false"
            >Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </md-drawer>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
import Chart from '../shared/Chart'
import ConfirmDialog from '../shared/ConfirmationDialog.vue'
import * as external from '../../feathers-client.js';

export default {
    name:"sourceDetails",
    data: () =>({
        dialog: false,
        dialogDelete: false,
        dialogCancel: false,
        showDetails: false,
        snackbar: false,
        runsLoading : true,
        limit: 5,
        totalRuns:0,
        sourceLinkCopied : "",
        snackbarText: "",
        sourceInfo: {},
        selectedSourcerun :{},
        resoults: [],
        headers: [
          { text: 'Date & Time', value: 'lastUpdated' },
          { text: 'Status', value: 'status' },
          { text: 'Records', value: 'records' },
          { text: 'Actions', value: 'actions' },
        ],
    }),
    mounted(){
      external.default.io.on("sourcerun created", (sourceRun) => { 
        if(this.sourceInfo._id == sourceRun.source_id) {
          this.resoults.push(sourceRun);
          this.totalRuns++;
        }
     });
     
     external.default.io.on("sourcerun updated", (sourceRun) => { 
        if(this.sourceInfo._id == sourceRun.source_id) {
          let updatedRun = this.resoults.find(srcRun => srcRun._id == sourceRun._id);

          if(updatedRun){
            this.resoults[this.resoults.indexOf(updatedRun)] = updatedRun;
          }
          
        }
     });
  },
    methods: {
      ...mapActions('sourcerun', { findSourceRun: 'find' }),
      changeDetailsHandler(sourceInfo){
        this.fetchDetails(sourceInfo._id);
        this.sourceInfo = sourceInfo;
        this.showDetails = true;
        this.sourceLinkCopied = sourceInfo.source;
      },

      fetchDetails(sourceID){
        this.runsLoading = true;
        this.findSourceRun({ query: { source_id: sourceID , $limit: this.limit } })
        .then(response => {
          this.totalRuns = response.total;
          this.resoults = response.data;
          this.runsLoading = false;
        }).catch(err => {
          console.error(err);
          this.runsLoading = false;
        })
      },

      removeSourceHandler(){
        const { Source } = this.$FeathersVuex;
        const mySource = new Source(this.sourceInfo);
        
        mySource.remove(this.sourceInfo._id)
         .then(()=> {
           this.dialogDelete = false;
           this.showDetails = false;
           this.onDeleteSource();
         })
         .catch((err) => {
           this.dialogDelete = false;
           console.error(err)
         });
      },

      editSourceHandler(){
        this.$router.push({ path: 'source', query: { id: this.sourceInfo._id } })
      },

      updateRunsTable(pagination) {
        pagination.itemsLength = this.totalRuns;
        if(pagination.page - pagination.pageCount == 0 && this.totalRuns > this.resoults.length){
          this.limit = pagination.itemsPerPage;
          this.loadMoreRuns(pagination.pageStop)
        }
      },

      loadMoreRuns(skip){
        this.findSourceRun({ query: { source_id: this.sourceInfo._id , $limit: this.limit , $skip: skip } })
        .then(response => {
          this.totalRuns = response.total;
          this.resoults = this.resoults.concat(response.data);
        }).catch(err => {
          console.error(err);
        })
      },

      onDeleteSource () {
        this.$emit('sourceDeleted');
      },

      resetAlert(){
        const { Alertreset } = this.$FeathersVuex;
        const newReset = new Alertreset(this.sourceInfo);
        newReset.update(newReset)
        .then(()=> {
          this.snackbar = true;
          this.snackbarText = "Successfully cleared alerts!"
        })
        .catch((err) => {
          console.error(err)
        });
      },

      runSource(){
        let newRun = {
          source_id: this.sourceInfo._id
        };

        const { Sourcerun } = this.$FeathersVuex;
        const newSourceRun = new Sourcerun(newRun);
        newSourceRun.save()
          .then(()=> {
            this.$emit('sourceRuned');
            this.dialog = false;
          }).catch(err => {
            console.error(err);
            this.snackbarText = 'Error running this source!';
            this.snackbar = true;
            this.dialog = false;
          });
      },

      stopRunHandler(item){
        this.selectedSourcerun = item;
        this.dialogCancel = true;
      },

      cancelSourceRun(){
        this.dialogCancel = false;
        const { Stoprun } = this.$FeathersVuex;
        const stopSrcRun = new Stoprun(this.selectedSourcerun);
        stopSrcRun.remove(this.selectedSourcerun._id)
          .then(()=> {
            this.snackbarText = 'Source canceled successfully';
            this.snackbar = true;
          }).catch((err) => {
            console.error(err);
          })
      },

      checkRuns(){
        let query = {
          source_id : this.sourceInfo._id,
          $skip : 0
        }

        if(this.totalRuns > 5){
          query.$skip = this.totalRuns - 5;
        }

        this.findSourceRun({ query: query })
        .then(response => {
          if(response.data.some(e => e.status === 'IN_QUEUE')){
            this.snackbarText = "Error: You can't run a source, if there are runs in queue.";
            this.snackbar = true;
          }else{
            this.dialog = true;
          }
        }).catch(err => {
          console.error(err);
          this.snackbarText = "Error: Something went wrong while checking sources.";
          this.snackbar = true;
          this.dialog = false;
        })  
      },

      handleSuccess(){
        alert('Source link copied to clipboard successfully!')
      },

      getUserRole(){
            let role = localStorage.getItem('user-role');
            if (role == 'Admin'){
                return true;
            }else{
                return false;
            }
        }
    },
     computed:{
      ...mapGetters('sourcerun', { findSourceInStore:'find'}),
      sourcerun(){
        return this.findSourceInStore({query:{source_id: this.sourceInfo._id}}).data
      }
    },
    props:['sourceDetails', 'chartData'],
    components:{
      Chart,
      ConfirmDialog
    }
}
</script>

<style lang="scss" scoped>
  .page-container {
    min-height: 300px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(#000, .12);
  }

  .md-drawer {
    height: 100vh;
    position: fixed;
    width: 630px;
    max-width: calc(100vw - 125px);
    background-color: rgb(242, 244, 247) !important;
  }

  .md-drawer::-webkit-scrollbar {
      display: none;
  }


  .md-content {
    padding: 16px;
  }

  .md-title{
    padding-left: 13px;
  }

  .buttons{
    margin-right: 10px;
  }

  .link{
    margin-right: 6px;
  }

  .details-header{
    display: flex;
    justify-content: space-between;
    background-color: white !important;
    position: sticky;
    top:0px;
  }

  .main-drawer-content{
    padding:40px;
  }


  .info-card{
    height: 56px;
    margin-bottom:20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px;
  }

  .info-card-text{
    font-size: 20px;
    color: gray;
  }

  .last-run-info-success{
    font-size: 20px;
    color:rgb(144, 202, 56);
  }

  .last-run-info-fail{
    font-size: 20px;
    color:rgb(228, 17, 17);
  }

  

  .frequency-info{
    font-size: 20px;
    color:rgb(47, 44, 221);
  }

  .spacer{
    margin-bottom: 8px;
  }

</style>