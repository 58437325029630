<template>
<div class="main">
  <v-snackbar
      v-model="snackbar"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >Close
        </v-btn>
      </template>
    </v-snackbar>

  <Toolbar />
  <div class="dashboard-container">
    <div class="chipsContainer">
    <FilterChip v-for="chip in this.chipData" :key="chip.name" :name="chip.name" :isActive="chip.isActive" @clicked="onClickChild"/>
    </div>
    <Statistics :chartData = "chartData" :stats="stats" :successRate="this.successRate" />
    <SourceDetails ref="sourceDetails" @sourceRuned="activateSnackbar('NOTIFICATION: Source has been run!')" @sourceDeleted="activateSnackbar('NOTIFICATION: Source has been deleted!')"/>
    <SourcesList  @sidebar-open="toggleSidebar" @row-updated="refreshSourceData"/>
  </div>
</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import Toolbar from '../components/shared/Toolbar'
	import SourceDetails from '../components/sources/SourceDetails'
	import SourcesList from '../components/sources/SourcesList'
	import Statistics from '../components/statistics/Statistics'
	import FilterChip from "../components/shared/FilterChip";
	import * as external from '../feathers-client';

	export default {
		name:'Dashboard',
		components : {
			Toolbar,
			SourceDetails,
			SourcesList,
			Statistics,
			FilterChip
		},
		data () {
			return {
				snackbar: false,
				text: "",
				sourceDetails: null,
				value: null,
				stats: {
					records: false,
					activeWorkers: false,
					activeSources:false
				},
				options: ['id', 'ID', 'option'],
					chipData:[
					{
						name: 'hourly',
						isActive: false
					},
					{
						name: 'daily',
						isActive: true
					},
					{
						name: 'monthly',
						isActive: false
					},
					{
						name: 'yearly',
						isActive: false
					}
				],
				chartData: [
					['', 'Success', 'Fail'],
					['', 9, 5], 
				],
				successRate : {}
			};
		},
		mounted(){
			this.getStatistics('daily')
			if(localStorage.getItem('userAdded')){
				this.text = 'NOTIFICATION: User has been added successfully!';
				this.snackbar = localStorage.getItem('userAdded');
			}

			external.default.io.on("statistics updated", (newStats) => {
				let activeChip = this.chipData.find(chip => chip.isActive === true);
				if(newStats.interval == activeChip.name){
					this.stats = newStats;
					this.successRate = {
						successfullRuns: this.stats.successful, 
						failedRuns: this.stats.failed,
					}
				}
			});
		},
		methods: {
			...mapActions('statistics', { findStatistics: 'find' }),
			toggleSidebar(source){
				this.sourceDetails = source;
				this.$refs.sourceDetails.changeDetailsHandler(source);
			},

			activateSnackbar(text) {
				this.text = text;
				this.snackbar = true;
			},

			refreshSourceData(source){
				this.$refs.sourceDetails.changeDetailsHandler(source);
			},

			onClickChild (value) {
				this.getStatistics(value);
				let activeChip = this.chipData.find(chip => chip.isActive === true);
				if (value === activeChip.name) {
					return
				}

				let foundedChipIndex = this.chipData.findIndex(chip => chip.name === value)
				this.chipData[foundedChipIndex].isActive = !this.chipData[foundedChipIndex].isActive;

				for(let i = 0; i < this.chipData.length; i++) {
					if(this.chipData[i].name !== this.chipData[foundedChipIndex].name) {
						this.chipData[i].isActive = false
					} 
				}
			},
			getStatistics(frequency){
				this.findStatistics({ query: { interval: frequency }}).then(response => {
					if (response.total > 0 ) {
						this.stats = response.data[0];
						this.successRate = {
							successfullRuns: this.stats.successful, 
							failedRuns: this.stats.failed
						}
					}
				}).catch(err => {
					console.error(err);
				})
			}
		}
	}
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

.main{
  background-color: transparent;
}

.dashboard-container{
  margin: auto;
  padding:40px;
  max-width: 1200px;
}

.chipsContainer {
  display: flex;
}

</style>
