import Vue from 'vue';
import Vuex from 'vuex';

import feathersVuex from './feathersVuex';

import localAuth from './localAuth';

const {
  service,
  auth,
  FeathersVuex,
} = feathersVuex;

Vue.use(Vuex);
Vue.use(FeathersVuex);

export default new Vuex.Store({
  modules: {
    localAuth
  },
  plugins: [
    service('users', {
      instanceDefaults: {
        strategy:'local',
        username: '',
      },
    }),
    service('source', {
      instanceDefaults: {},
    }),
    service('target', {
      instanceDefaults: {},
    }),
    service('sourcerun', {
      instanceDefaults: {},
    }),
    service('statistics', {
      instanceDefaults: {},
    }),
    service('elasticsecrets', {
      instanceDefaults: {},
    }),
    service('preview', {
      instanceDefaults: {},
    }),
    service('stoprun', {
      instanceDefaults: {},
    }),
    service('alertreset', {
      instanceDefaults: {},
    }),
    auth({
      userService: 'users',
    }),
  ],
});