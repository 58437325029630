import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";

const socket = io(process.env.VUE_APP_FEATHERS_API, {
  transports: ["websocket"],
});

let socketConnectionStateError = { state: "false" };
const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth({ storage: window.localStorage }));

feathersClient.io.on("connect_error", () => {
  socketConnectionStateError.state = "connect_error";
  console.error("connect_error event", socketConnectionStateError);
  return "error";
});

feathersClient.io.on("connect", () => {
  socketConnectionStateError.state = "successfully_connected";
});


export default feathersClient;
