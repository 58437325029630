<template>
    <v-dialog
        v-model="willShowDialog"
        width="280"
        @input="v => v || close()"
    >

    <v-card>
    <v-card-title class="text-h7 grey lighten-2">
        <p>{{headerText}}</p>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-actions class="dialog-footer">
        <v-btn
            color="primary"
            text
            @click="selectYes()"
        >
        YES
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="selectNo()"
        >
        NO
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>

</template>

<script>
export default {
    name:"ConfirmationDialog",
    data () {
    return {
      willShowDialog : false
    }
  },
    props:['headerText','dialog'],
    methods: {
        selectYes(){
            this.$emit('selectedYes')
        },
        selectNo(){
            this.$emit('selectedNo')
        },
        close(){
            this.$emit('dialogClosed')
        }
    },
    watch: { 
        dialog: function(newVal) {
            this.willShowDialog = newVal;
          },
        },
}
</script>

<style>

    .dialog-footer{
        display: flex;
        justify-content: space-around;
    }

</style>