<template>
  <div>
    <BasicToolbar />
    <v-container fluid>
      <v-slide-y-transition mode="out-in">
        <v-layout column align-center>
          
          <v-form
            v-if="!loading"
            v-model="valid"
            @submit.prevent="addKey"
            @keydown.prevent.enter>
              <v-card class="form-container" elevation="0">
                <div class="form-fields-group">
                  <v-text-field
                      v-model="keyObj.key"
                      label="Key"
                      outlined
                      required
                      :rules="[rules.required, rules.min]"
                  ></v-text-field>
                  <v-text-field
                      v-model="keyObj.secret"
                      label="Secret"
                      :rules="[rules.required, rules.min]"
                      outlined
                      required
                  ></v-text-field>
                </div>
                
              </v-card>

              <div class="error2" v-if="error">
              <p>{{error}}</p>
              </div>
            
            <v-btn class="srcBtn signBtn" type="submit" :disabled="!valid">SAVE</v-btn>
          </v-form>
          <div class="spinner" v-if="loading">
            <v-container fill-height fluid >
              <v-row align="center" justify="center">
                <v-progress-circular indeterminate :size="150" color="primary" class=""></v-progress-circular>
              </v-row>
            </v-container>
          </div>
        </v-layout>
      </v-slide-y-transition>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicToolbar from '../shared/BasicToolbar.vue'

export default {
  name: 'AddKey',
  data: () => ({
    error: "",
    show1: false,
    valid: false,
    keyObj: {
      strategy:'local',
      key:'',
      secret:''
    },
    rules: {
      required: value => !!value || 'This field is required.',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
  }),
  computed: {
    ...mapState('elasticsecrets', { loading: 'isCreatePending' }),
  },
  methods: {
    addKey() {
      if (this.valid) {
        const { Elasticsecret } = this.$FeathersVuex;
        const elasticsecret = new Elasticsecret(this.keyObj);
        elasticsecret.save()
          .then(() => {
            this.error = "";
            this.$router.push('/keys');
          }).catch(err => {
            this.error = err.message
        });
      }
    }
  },
  components:{
    BasicToolbar
  }
};
</script>

<style scoped>
  .v-form{
    margin:40px;
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error2{
    color: red;
  }

  .form-container{
    width: 560px;
    margin:32px 0px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-fields-group{
    width: 400px;
    padding: 40px 0px;
  }

  .signBtn{
    width: 360px;
  }

   .spinner {
    display: block;
    position: fixed;
    z-index: 1031; 
    top: 50%;
    right: 50%; 
    margin-top: -75px; 
    margin-right: -75px;
  }

</style>