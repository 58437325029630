<template> 
    <div class="users-container">
        <Users />
        <UsersList />
    </div>
  

</template>

<script>
import Users from '../components/users/Users.vue'
import UsersList from '../components/users/UsersList.vue'

export default {
    name:'usersView',
    components:{
        Users,
        UsersList

    }
}
</script>

<style>

</style>