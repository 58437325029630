<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout column align-center>
        
        <v-form
          v-if="!loading"
          v-model="valid"
          @submit.prevent="signUp"
          @keydown.prevent.enter>
            <v-card class="form-container" elevation="0">
              <div class="form-fields-group">
                <v-text-field
                v-model="user.email"
                label="Email"
                outlined
                required
                :rules="emailRules"
              ></v-text-field>
              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="user.password"
                outlined
                label="Password"
                :rules="[rules.required, rules.min]"
                hint="At least 8 characters"
                required
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
              ></v-text-field>
               <v-chip-group
                mandatory
               >
              <FilterChip v-for="chip in this.chipData" :key="chip.name" :name="chip.name" :isActive="chip.isActive" @clicked="onClickChild"/>
              </v-chip-group>
              </div>
               
            </v-card>

            <div class="error2" v-if="error">
             <p>{{error}}</p>
            </div>
          
          <v-btn class="srcBtn signBtn" type="submit" :disabled="!valid">SAVE</v-btn>
        </v-form>
        <v-progress-circular
          v-if="loading"
          :size="70"
          :width="7"
          indeterminate
          color="primary">
        </v-progress-circular>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FilterChip from "../../components/shared/FilterChip";
export default {
  name: 'signUp',
  components : {
      FilterChip
    },
  data: () => ({
    error: "",
    show1: false,
    valid: false,
    user: {
      strategy:'local',
      email: '',
      password: '',
      role: 'User',
    },
    steperOneOptions:  ['Frequency', 'FRQC', 'frequency', 'frqnc'],
    tags: [
      'User',
      'Admin',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],  
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
    },
    chipData:[
      {
        name: 'User',
        isActive: true
      },
      {
        name: 'Admin',
        isActive: false
      },
    ]  
  }),
  computed: {
    ...mapState('users', { loading: 'isCreatePending' }),
  },
  methods: {
    signUp() {
      if (this.valid) {
        const { User } = this.$FeathersVuex;
        const user = new User(this.user);
        user.save()
          .then(() => {
            this.error = "";
            this.$router.push('/dashboard');
            localStorage.setItem('userAdded', 'true')
            setTimeout(()=>{
            localStorage.removeItem('userAdded')
            },1000);
          }).catch(err => {
            if(err.name == "GeneralError"){
             this.error = "User already exists!"
             return;
            }
            this.error = "Error!"
        });
      }
    },
    selectTag(chip){
      this.user.role = chip;
    },
    onClickChild (value) {
      let activeChip = this.chipData.find(chip => chip.isActive === true);
      if (value === activeChip.name) {
        return
      }
      let foundedChipIndex = this.chipData.findIndex(chip => chip.name === value)
      this.chipData[foundedChipIndex].isActive = !this.chipData[foundedChipIndex].isActive;

      for(let i = 0; i < this.chipData.length; i++) {
        if(this.chipData[i].name !== this.chipData[foundedChipIndex].name) {
         this.chipData[i].isActive = false
        }
      } 
      this.user.role = value;
    }
  },
};
</script>

<style scoped>
  .v-form{
    margin:40px;
    width: 560px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error2{
    color: red;
  }

  .form-container{
    width: 560px;
    margin:32px 0px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-fields-group{
    width: 400px;
    padding: 40px 0px;
  }

  .signBtn{
    width: 360px;
  }

</style>