<template>
  <v-layout align-center justify-center column fill-height>
      <v-flex row align-center>
          <v-progress-circular v-if="!showUnauthorisedDialog" indeterminate :size="200" color="primary" class=""></v-progress-circular>
      </v-flex>
      <ErrorDialog 
        v-if="showUnauthorisedDialog" 
        :errorHeader="'Authentication error'" 
        :errorMessage="'You are not allowed to acces this web application.Contact the admin for futher instructions.'" 
        :isPersistent="false" 
      />
  </v-layout>
  
</template>

<script>
import { mapActions } from 'vuex';
import ErrorDialog from '../shared/ErrorDialog'

export default {
  name: 'Callback',
  data:()=> ({
    showUnauthorisedDialog : false,
  }),
  beforeMount() {
    if(window.location.hash) {
      const hash = window.location.hash.substring(1);
      let fragments = hash.split('access_token=');
      let google_access_token = fragments[1];
      
      if(google_access_token){
        localStorage.setItem('feathers-jwt', google_access_token);
      } 
    } else {
      alert('NO TOKEN')
    }
  },
  mounted(){
    this.authenticate().then((userData) => {
      localStorage.setItem('user-role', userData.user.role);
      this.$router.push('dashboard');
    }).catch(error => {
      console.error(error)
      this.showUnauthorisedDialog = true;
    });
  },
  methods: {
   ...mapActions('auth', ['authenticate'])
  },
  components:{
    ErrorDialog
  }
};
</script>

<style lang="scss" scoped>

</style>
