var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistics"},[_c('v-card',{staticClass:"chart",attrs:{"elevation":"1"}},[_c('Chart',{attrs:{"successRate":this.successRate}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"records",attrs:{"elevation":"1"}},'v-card',attrs,false),on),[_c('span',{staticClass:"card-text"},[_vm._v("RECORD")]),(_vm.stats.records)?_c('p',{staticClass:"card-text card-data"},[_vm._v(_vm._s(_vm.stats.records))]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]}}])},[_c('span',[_vm._v("How many records are currently in elastic search.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"records",attrs:{"elevation":"1"}},'v-card',attrs,false),on),[_c('span',{staticClass:"card-text"},[_vm._v("ACTIVE SOURCES")]),(_vm.stats.activeSources)?_c('p',{staticClass:"card-text card-data"},[_vm._v(_vm._s(_vm.stats.activeSources))]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]}}])},[_c('span',[_vm._v("Active sources for selected period of time.")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"records",attrs:{"elevation":"1"}},'v-card',attrs,false),on),[_c('span',{staticClass:"card-text"},[_vm._v("ACTIVE WORKERS")]),(_vm.stats.activeWorkers)?_c('p',{staticClass:"card-text card-data"},[_vm._v(_vm._s(_vm.stats.activeWorkers))]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]}}])},[_c('span',[_vm._v("Number of current active workers.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }