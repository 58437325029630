<template>
  <div class="dashboard-container">
    <v-card>
      <v-card-title>
        Users
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        @pagination="updatePagination"
        :headers="headers"
        :items="rows"
        :search="search"
        :footer-props="{
            'items-per-page-options': [5, 10, 15]
          }"
        :items-per-page="5"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
  export default {
    mounted() {
      this.findusers({ query: { $limit: this.limit}})
        .then(response => {
          this.totalUsers = response.total;
          this.rows = response.data;
        }).catch(err => {
          console.error(err);
        })
    },
    methods: {
      ...mapActions('users', { findusers: 'find' }),
      updatePagination (pagination) {
        pagination.itemsLength = this.totalUsers;
        if(pagination.page - pagination.pageCount == 0){
          this.limit = pagination.itemsPerPage;
          this.loadMoreUsers(pagination.pageStop);
        }
      },
      loadMoreUsers(skip){
        this.findusers({ query: { $limit: this.limit , $skip:skip}})
        .then(response => {
          this.totalUsers = response.total;
          this.rows = this.rows.concat(response.data)
        }).catch(err => {
          console.error(err);
        })
      }
    },
    computed:{
      ...mapGetters('users', { findusersInStore:'find'}),
      users(){
        return this.findusersInStore({query:{}}).data
      }
    },
    data () {
      return {
        limit:5,
        totalUsers:0,
        search: '',
        headers: [
          { text: 'email', align: 'start', value: 'email' },
          { text: 'role', value: 'role' },
        ],
        rows: [],
      }
    },
  }
</script>


<style>

.v-icon:after {
  opacity: 0 !important;
}


</style>