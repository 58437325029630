<template>
   <v-app-bar class="stepper-header" flat>
        <div class="appbar-left-side">
            <v-icon
                x-large
                @click="back()"
            >mdi-chevron-left
          </v-icon>

          <span class="add-link-text">Back</span>
        </div>
        
      </v-app-bar>
</template>

<script>
export default {
    name:'BasicToolbar',
    methods:{
      back(){
        this.$router.go(-1);
      }
    }

}
</script>

<style>

</style>