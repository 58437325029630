<template>
  <v-app-bar flat>
        <div class="appbar-left-side">
            <v-icon
              x-large
              @click="back()"
              :ripple="false"
            >mdi-chevron-left
          </v-icon>

          <span class="add-link-text">Back</span>
        </div>
        <div class="appbar-right-side">
          <v-btn
            depressed
            class="white--text"
            color="#063BFF"
            @click="onAddUser()"
          >
            ADD USER
          </v-btn>
        </div>
        
      </v-app-bar>
</template>

<script>
export default {
    name:'users',
     methods:{
        onAddUser(){
            this.$router.push('/users/add')
        },
        back(){
          this.$router.go(-1);
        }
    }
}
</script>

<style>

.v-toolbar__content{
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.v-icon:after {
  opacity: 0 !important;
}


</style>