import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./router/index";
import store from "./store/store";

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import vuetify from "./plugins/vuetify";
import VueGoogleCharts from "vue-google-charts";
import VueClipboards from 'vue-clipboards';


Vue.config.productionTip = false;
export const bus = new Vue();

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(VueGoogleCharts);
Vue.use(VueClipboards);

const router = new VueRouter({
  routes,
  mode: "history",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export const EventBus = new Vue();
