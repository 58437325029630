<template>
  <Login />
</template>

<script>
import Login from '../components/auth/Login'
export default {
    name:'LoginView',
    components: {
        Login
    }
}
</script>

<style>

</style>