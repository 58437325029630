<template>
  <v-card>
    <v-card-title>
      Sources
      <v-spacer></v-spacer>
      <div class="search-holder">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          dense
          single-line
          hide-details
          outlined
        ></v-text-field>
      </div>
    </v-card-title>
    <v-data-table
      loading-text="Loading... Please wait"
      :loading = "loaderState"
      :itemsLength="200"
      :headers="headers"
      :items="rows"
      :originalItemsLength="200"
      :items-per-page="5"
      @click:row="rowClick"
      @pagination="updateSourcesTable"
      :footer-props="{
        'items-per-page-options': [5, 10, 15],
      }" 
    >
     <template v-slot:[`item.alert`]="{ item }">
        <div class="select-holder">
          <v-badge
            :content="item.alert"
            :value="item.alert"
            color="red"
            bordered
            overlap
          >
            <v-icon>
              notifications
            </v-icon>
          </v-badge>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters , mapActions } from 'vuex';
import * as external from '../../feathers-client';

  export default {
    mounted() {
      this.findSource({ query: { $limit: this.limit } })
        .then(response => {
          this.totalSources = response.total;
          this.rows = response.data;
          this.loaderState = false;
        }).catch(err => {
          console.error(err);
        });

        external.default.io.on("source removed", (src) => {
          this.deleteSrc(src._id);
          this.totalSources--;
        });

        external.default.io.on("source created", (src) => {
          this.rows.push(src);
          this.totalSources++;
          
        });

        external.default.io.on("source updated", (src) => {
          let updatedSource = this.rows.find(source => source._id == src._id);

          if(updatedSource){
            this.rows[this.rows.indexOf(updatedSource)] = updatedSource;
            this.rowUpdated(src);
          }
          
        });
    },
    data () {
      return {
        limit:5,
        search: '',
        searchDelay:null,
        totalSources:0,
        loaderState: true,
        headers: [
          { text: 'ALERT', align: 'start', value: 'alert' },
          { text: 'TITLE', value: 'title' },
          { text: 'SOURCE', value: 'source' },
          { text: 'FREQUENCY', value: 'frequency' },
          { text: 'LAST RUN', value: 'lastRun' },
        ],
        rows: [],
      }
    },
    methods: { 
      ...mapActions('source', { findSource: 'find' }),
      rowClick: function (source) {
        this.$emit('sidebar-open', source)
      },

      rowUpdated: function(source){
        this.$emit('row-updated', source);
      },
      deleteSrc(id){
        this.rows = this.rows.filter((row) => row._id != id)
      },
      updateSourcesTable(pagination) {
        pagination.itemsLength = this.totalSources;
        if(pagination.page - pagination.pageCount == 0 && this.totalSources > this.rows.length){
          this.limit = pagination.itemsPerPage;
          this.loadMoreSources(pagination.pageStop, this.search);
        }
      },
      loadMoreSources(skip, search){

        let query = {
          $limit:this.limit,
          $skip:skip
        }

        if(search.length > 2) {
          query.$search = search;
        }

        this.findSource({ query: query})
          .then(response => {
            this.totalSources = response.total;
            this.rows = this.rows.concat(response.data)
          }).catch(err => {
            console.error(err);
          })
      }
    },
    computed:{
      ...mapGetters('source', { findSourceInStore:'find'}),
    },
    watch:{
      search:function(val){
         clearTimeout(this.searchDelay);
          this.searchDelay = setTimeout(() => {
              if(val.length < 1){
                this.loaderState = true;
                this.rows = [];
                this.findSource({ query: { $limit: this.limit} })
                  .then(response => {
                    this.loaderState = false;
                    this.totalSources = response.total;
                    this.rows = response.data;
                  }).catch(err => {
                    console.error(err);
                    this.loaderState = false;
                  });
              }else if(val.length > 2){
                this.loaderState = true;
                this.findSource({ query: { $limit: this.limit , $search: val} })
                    .then(response => {
                      this.loaderState = false;
                      this.totalSources = response.total;
                      this.rows = response.data;
                    }).catch(err => {
                      console.error(err);
                      this.loaderState = false;
                    })
              }
          }, 700)
      }
    },
    props:['delete']
  }
</script>
<style scoped lang="scss">

.select-holder{
  i{
    font-size: 28px !important;
  }

   .v-badge__wrapper{

    .v-badge__badge{
      height: 17px !important;
      min-width: 15px !important;
    }
  
  }

   
}

</style>