<template>
<div class="signup-container">
    <BasicToolbar />
    <Signup />
</div>
  
</template>

<script>
import Signup from '../components/auth/Signup.vue'
import BasicToolbar from '../components/shared/BasicToolbar.vue'

export default {
    name:'signupView',
    components:{
        Signup,
        BasicToolbar
    }
}
</script>

<style>

</style>