<template>
  <div>
    <KeyHeader />
    <KeysList />
  </div>
</template>

<script>
import KeyHeader from '../components/keys/KeysHeader.vue'
import KeysList from '../components/keys/KeysList.vue'

export default {
    name:'keys',
    components: {
        KeyHeader,
        KeysList
    }

}
</script>

<style>

</style>