<template>
  <v-app :style="{backgroundColor:'rgb(6, 59, 255, 0.02)'}">
    <v-main id="app-wrap">

      <div v-if="socketError === 'connect_error'">
        <ErrorDialog :errorHeader="'CONNECTION ERROR'" :errorMessage="'You have lost the connection'" :isPersistent="true" />
      </div>
      <router-view @sourceAdded="activateSnackbar"/>
    </v-main>
    
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import * as external from './feathers-client.js';
import ErrorDialog from './components/shared/ErrorDialog';

export default {
  name: 'App',
  metaInfo: {
      title: 'Job Search Engine',
  },
  components: {
   ErrorDialog
  },
  data: () => ({
    error: "",
    socketError: "",
    snackbar: false,
    text: "",
  }),
  updated(){
      external.default.io.on("connect_error", () => {
      this.socketError = "connect_error"
    });
    external.default.io.on("connect", () => {
      this.socketError = "connect_success"
    });
  },
  methods: {
   ...mapActions('auth', ['authenticate']),
   activateSnackbar(text) {
    this.text = text;
    this.snackbar = true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Material+Icons");

@media only screen and (max-width: 1170px) {
  body{
   display: none !important;
 }
}

</style>
