<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :persistent="isPersistent"
      max-width="290"
      @input="v => v || redirectToLogin()"
    >
      <v-card class="error-card">

        <v-card-title class="headline">
          {{errorHeader}}
        </v-card-title>

        <v-card-text>{{errorMessage}}</v-card-text>

        <v-btn 
          class="auth-error-btn"
          v-if="errorHeader == 'Authentication error'" 
          @click="redirectToLogin()"
          >
           OK 
        </v-btn>

        <v-btn
          v-if="errorHeader == 'Error'"
          text
          @click="dialog = false"
          >Close</v-btn>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
  export default {
		name: 'ErrorDialog',
    data () {
      return {
        dialog: true,
      }
    },
    methods:{
      redirectToLogin(){
        this.$router.push('login');
      }
    },
    props:['errorHeader','errorMessage', 'isPersistent']
  }
</script>

<style scoped>
  .error-card{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .auth-error-btn{
    margin:20px;
  }
</style>