<template>
  <GChart
    :settings="{packages: ['bar']}"    
    :data="chartData"
    :options="chartOptions"
    :createChart="(el, google) => new google.charts.Bar(el)"
    @ready="onChartReady"
  />
</template>

<script>
import { GChart } from 'vue-google-charts'
export default {
  name: 'App',
  components: {
    GChart
  },
  data () {
    return {
      chartsLib: null,
      chartData: [
        ['', 'Success', 'Fail'],
        [' ', 7, 5], 
      ],
    }
  },
  props: {successRate: {type: Object}},
  watch: { 
        successRate:function(newVal) {
            let arr = [' ', +newVal.successfullRuns, +newVal.failedRuns];
            this.chartData.pop();
            this.chartData.push(arr);
            const finalData = JSON.parse(JSON.stringify(this.chartData));
            this.chartData = [...finalData];
          },
        },
  computed: {
    chartOptions () {
      if (!this.chartsLib) return null
      return this.chartsLib.charts.Bar.convertOptions({
        chart: {
          title: 'Source success rate',
        },
        bars: 'horizontal',
        hAxis: { format: 'decimal' },
        height: 150,
        width:500,
        colors: ['#0000FF',  '#FF0000']
      })
    }
  },
   mounted: function () {
    if(this.successRate) {
    let arr = [' ', +this.successRate.successfullRuns, +this.successRate.failedRuns];
            this.chartData.pop();
            this.chartData.push(arr);
            const finalData = JSON.parse(JSON.stringify(this.chartData));
            this.chartData = [...finalData];
    } 
  },
  methods: {
    onChartReady (chart, google) {
      this.chartsLib = google
    },
  }
}
</script>


<style>

</style>