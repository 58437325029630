<template>
  <div class="statistics">
      <v-card
        class="chart"
        elevation="1"
      >
        <Chart :successRate="this.successRate"/>
      </v-card>

       <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="records"
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <span class="card-text">RECORD</span>
              <p class="card-text card-data" v-if="stats.records">{{stats.records}}</p>
              <v-progress-circular v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card>
          </template>
          <span>How many records are currently in elastic search.</span>
        </v-tooltip>


      <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="records"
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <span class="card-text">ACTIVE SOURCES</span>
              <p class="card-text card-data" v-if="stats.activeSources">{{stats.activeSources}}</p>
              <v-progress-circular v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card>
          </template>
          <span>Active sources for selected period of time.</span>
       </v-tooltip>

     

       <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
             <v-card
              class="records"
              elevation="1"
              v-bind="attrs"
              v-on="on"
            >
              <span class="card-text">ACTIVE WORKERS</span>
              <p class="card-text card-data" v-if="stats.activeWorkers">{{stats.activeWorkers}}</p>
              <v-progress-circular v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card>
          </template>
          <span>Number of current active workers.</span>
       </v-tooltip>


    </div>
</template>

<script>
import Chart from '../shared/Chart'
export default {
    name:"statistics",
    components:{
        Chart
    },
    props: ['successRate','stats',],
}
</script>

<style>

.statistics{
  display: flex;
  justify-content: space-between;
}
.chart{
  height: 190px;
  width:552px;
  background-color: white;
  padding:20px;
  margin-bottom:40px;
  border-radius: 6px;
  border: 0.5px solid rgb(228, 222, 222);
}

.records{
  height: 190px;
  width:170px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.card-text{
  width:80%;
  color:rgb(131, 129, 129)
}

.card-data{
  width:80%;
  font-size: 34px;
  color:black;
}

</style>
