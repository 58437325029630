import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Stepper from "../views/Stepper.vue";
import Callback from "../components/auth/Callback.vue"
import Users from "../views/Users.vue"
import Signup from "../views/Signup.vue"
import store from "../store/store.js";
import AddKey from "../components/keys/AddKey"
import Keys from '../views/Keys.vue'

function isLoggedIn(to, from, next) {
  store
    .dispatch("auth/authenticate")
    .then(() => {
      next();
    })
    .catch(() => {
      next("/login");
    });
}


export default [
  {
    path:'/',
    name: 'Home',
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/source",
    name: "Source",
    component: Stepper,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/users/add",
    name: "Signup",
    component: Signup,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/keys",
    name: "keys",
    component: Keys,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/keys/add",
    name: "AddKey",
    component: AddKey,
    beforeEnter: isLoggedIn,
  },

];