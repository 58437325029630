<template>
  <div class="login-container">

  <v-container fill-height fluid v-if="spinnerState">
  <v-row align="center" justify="center">
  <v-progress-circular indeterminate :size="150" color="primary"></v-progress-circular>
  </v-row>
  </v-container>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="login-form"
        v-else
      >
      
      <v-text-field
        id="email"
        v-model="email"
        :rules="emailRules"
        placeholder="Enter e-mail"
        outlined
        required
      ></v-text-field>

    <v-text-field
          id="password"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          placeholder="Enter password"
          outlined
          hint="At least 4 characters"
          counter
          @click:append="show1 = !show1"
      ></v-text-field>

       <div class="error2">
        <p>{{error}}</p>
        </div>
  
      
      <v-btn
        :disabled="!isComplete"
        class="mr-4 loginBtn"
        @click="validate"
      >
        LOG IN
      </v-btn>
      <br>
      <a :href="googleURL" class="google-btn-wrapper">
        <div class="google-btn">
          <div class="google-icon-wrapper">
            <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
          </div>
          <p class="btn-text"><b>CONTINUE WITH GOOGLE</b></p>
        </div>
      </a>


    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';


  export default {
    data: () => ({
      errorSocket: '',
      spinnerState: false,
      googleURL: process.env.VUE_APP_GOOGLE_URL,
      valid: false,
      show1: false,
        password: '',
        rules: {
          required: value => !!value || 'Password is required.',
          min: v => v.length >= 4 || 'Min 4 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      error: "",
    }),
    methods: {
      ...mapActions('auth', ['authenticate']),
      validate () {
        this.$refs.form.validate()
        this.spinnerState = true;
        if(this.valid){
            this.authenticate({
              strategy:"local",
              email: this.email,
              password: this.password
            }).then((userData) => { 
              // aluserData.user.role
              localStorage.setItem('user-role', userData.user.role);
              this.error = ""
              this.$router.push('dashboard')
              this.spinnerState = false;
            }).catch(err => {
              this.spinnerState = false;
              if(err.name === "NotAuthenticated")
              this.error = "Wrong Email or Password"
            })
        }
      },
       validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
       }
    },
    computed: {
     isComplete () {
      if (!this.email || !this.password) {
        return false;
      }
      if(this.password.length <= 3) {
        return false;   
       }
      if(!this.validateEmail(this.email)) {
        return false;
      }
      return true;
  },       
},
mounted () {
  if (
        window.localStorage.getItem("feathers-jwt") !== null &&
        this.$route.path == "/" || this.$route.path == "/login"
      ) {
        this.$router.push("/dashboard").catch(() => true); // redirect to home, for example
      }
},
  }
</script>

<style lang="scss" scoped>

  .md-progress-bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

   .error2{
    color: red;
  }

  .login-container{
      height: 100%;
      display: flex;
      align-items: baseline;
  }

  .container-form{
      margin: auto;
      margin-top: 50px;
      width: 500px;
  }

  .login-form{
      width: 500px;
      margin:auto;
      text-align: center;
  }
  
  .loginBtn{
    background-color: #063BFF !important;
    color:white !important;
    height: 45px !important;
    width: 360px;
    margin-bottom:20px;
    margin-left: 16px !important;
  }

  .google-btn-wrapper:hover{
    text-decoration: none;
  }

  .google-btn {
    width: 354px;
    height: 42px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.25);
    margin:auto;
    cursor: pointer;

    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: white;
    }
    .google-icon {
      position: absolute;
      margin-top: 11px;
      margin-left: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      color: black;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
      padding:10px;
    }

    a{
      text-decoration: none;
    }
  }

</style>
