<template>
   <md-toolbar class="app-toolbar" id='toolbar' md-elevation="0">
       <div class="toolbar-content" v-bind:class="{ adminToolbar: getUserRole() }">
           <div class="left-side">
              <h3 class="md-title">Dashboard</h3>
           </div>
           <div class="right-side">
                <v-btn v-if="getUserRole()" class="srcBtn" to="/source">ADD SOURCE</v-btn>
                <div>
                    <router-link
                        v-if="getUserRole()"
                        to="/users"
                    >
                        <md-button class="md-icon-button">
                            <md-icon>manage_accounts</md-icon>
                        </md-button>
                    </router-link>

                    <router-link
                        v-if="getUserRole()"
                        to="/keys"
                    >
                        <md-button class="md-icon-button">
                            <md-icon>vpn_key</md-icon>
                        </md-button>
                    </router-link>
                    
                    <md-button class="md-icon-button" @click="logout()">
                        <md-icon>login</md-icon>
                    </md-button>
                </div>
           </div>
       </div>
    </md-toolbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name:'toolbar',
    computed:{
        ...mapState('auth', { user:'payload' }),
    },
    methods:{
        ...mapActions('auth', { authLogout: 'logout' }),
        logout(){
            this.authLogout().then(() => {
                localStorage.removeItem('user-role');
                this.$router.push('/login');
            });
        },
        getUserRole(){
            let role = localStorage.getItem('user-role');
            if (role == 'Admin'){
                return true;
            }else{
                return false;
            }
        }
    }


}
</script>

<style lang="scss">


#toolbar{
  background-color: white;
  position:sticky;
  top:0px;
}

.toolbar-content{
    width:100%;
    display: flex;
    justify-content: space-between;
    
}

.adminToolbar{
    align-items: baseline;
}


.right-side{
    display: flex;
    height: 40px;

    a{
        text-decoration: none !important;
    }
        
}



.srcBtn{
    background-color: #063BFF !important;
    color:white !important;
    margin:0px 15px !important;

    
}

</style>