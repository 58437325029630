<template>
  <div class="chips">

<v-chip-group
    mandatory
  >
   <v-chip
      class="ma-2"
			:color="isActive ? 'primary' : 'default'"
      @click="toggle(name)"
    >
      {{ name}}
    </v-chip>
    </v-chip-group>
  </div>
</template>


<script>
  export default {
		name: "FilterChip",
    props: {
    name: String,
    isActive: Boolean
    },
    methods: {
      toggle(name) {
      this.$emit('clicked', name)
      },
    }
  }
</script>

<style scoped>
.chips {
	display: flex;
}
</style>